import {
    AssetsIcon,
    AttributePriceIcon,
    CopyIcon,
    LocaleIcon,
    ProductIcon,
    ProductModelIcon,
    SectionTitle,
    TagIcon,
} from 'akeneo-design-system';
import { DetailedReportModel } from '../model/SynchronizationReportModel';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { DetailedReportBlock } from './DetailedReportBlock';

const DetailedContent = styled.div`
    display: flex;
    gap: 15px;
    flex-flow: row wrap;
`;

type Props = {
    detailedReport: DetailedReportModel | null;
};

export const DetailedReport = ({ detailedReport }: Props) => {
    return (
        <>
            <SectionTitle>
                <SectionTitle.Title>
                    <FormattedMessage
                        id='avRFVK'
                        defaultMessage='Detailed Report'
                    />
                </SectionTitle.Title>
            </SectionTitle>

            <br />

            <DetailedContent>
                {/* Product */}
                {detailedReport?.product && (
                    <DetailedReportBlock
                        title={
                            <FormattedMessage
                                id='7NFfmz'
                                defaultMessage='Products'
                            />
                        }
                        icon={<ProductIcon color='#a1a9b7' size={30} />}
                        detailedReport={detailedReport.product}
                    />
                )}

                {/* product Model  */}
                {detailedReport?.product_model && (
                    <DetailedReportBlock
                        title={
                            <FormattedMessage
                                id='Tbe1YB'
                                defaultMessage='Product models'
                            />
                        }
                        icon={<ProductModelIcon color='#a1a9b7' size={30} />}
                        detailedReport={detailedReport.product_model}
                    />
                )}

                {/* Variants */}
                {detailedReport?.product_variant && (
                    <DetailedReportBlock
                        title={
                            <FormattedMessage
                                id='j9HJG/'
                                defaultMessage='Product variants'
                            />
                        }
                        icon={<CopyIcon color='#a1a9b7' size={30} />}
                        detailedReport={detailedReport.product_variant}
                    />
                )}

                {/* Medias  */}
                {detailedReport?.media && (
                    <DetailedReportBlock
                        title={
                            <FormattedMessage
                                id='hmZ3Bz'
                                defaultMessage='Media'
                            />
                        }
                        icon={<AssetsIcon color='#a1a9b7' size={30} />}
                        detailedReport={detailedReport.media}
                    />
                )}

                {/* Metafields */}
                {detailedReport?.metafield && (
                    <DetailedReportBlock
                        title={
                            <FormattedMessage
                                id='i1xTYg'
                                defaultMessage='Metafields'
                            />
                        }
                        icon={<TagIcon color='#a1a9b7' size={30} />}
                        detailedReport={detailedReport.metafield}
                    />
                )}

                {/* Locales */}
                {detailedReport?.locale && (
                    <DetailedReportBlock
                        title={
                            <FormattedMessage
                                id='dG+jsJ'
                                defaultMessage='Locales'
                            />
                        }
                        icon={<LocaleIcon color='#a1a9b7' size={30} />}
                        detailedReport={detailedReport.locale}
                    />
                )}

                {/* Markets pricing */}
                {detailedReport?.markets_pricing && (
                    <DetailedReportBlock
                        title={
                            <FormattedMessage
                                id='0c+HsX'
                                defaultMessage='Markets Pricing'
                            />
                        }
                        icon={<AttributePriceIcon color='#a1a9b7' size={30} />}
                        detailedReport={detailedReport.markets_pricing}
                    />
                )}
            </DetailedContent>
        </>
    );
};
