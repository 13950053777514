import { apiFetch } from '../../../util/apiFetch';

type Result = {
    isEmpty: boolean;
};

export const isMetafieldMappingEmpty = (currentStoreId: string) =>
    apiFetch<Result>(
        `${currentStoreId}/synchronization/is-metafield-mapping-empty`
    ).then((result) => result);
