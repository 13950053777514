import {
    AssetsIllustration,
    Button,
    Checkbox,
    CloseIcon,
    getColor,
    Helper,
    Information,
    Search,
    SelectInput,
    Table,
    useBooleanState,
} from 'akeneo-design-system';
import React, { useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import { Loading } from '../../../component';
import { useCurrentStore } from '../../MultiStore';
import { retrievePimAttributesForMetafieldsSelection } from '../api/retrievePimAttributesForMetafieldsSelection';
import { NoPimAttribute } from './NoPimAttribute';
import { SelectedFilter } from './SelectedFilter';
import { MetafieldDeselectionModal } from './MetafieldDeselectionModal';
import { NoPimAttributeInSearch } from './NoPimAttributeInSearch';
import {
    MetafieldExistingDefinition,
    retrieveExistingShopifyMetafieldsDefinition,
} from '../api/retrieveExistingShopifyMetafieldsDefinition';
import { useSaveMetafieldMapping } from '../useSaveMetafieldMapping';
import { existingDefinitionMapping } from '../api/saveMetafieldMapping';
import {
    getMetafieldMapping,
    Metafield,
    MetafieldDefinition,
} from '../api/getMetafieldMapping';
import * as MetafieldDefinitionType from '../MetafieldDefinitionType';
import * as PimAttributeType from '../PimAttributeType';
import { InstabilityStickyHelper } from '../../../component/PageHeader/InstabilityStickyHelper';
import * as InstabilityType from '../../../util/InstabilityType';

const MAX_SELECTED_ATTRIBUTES = 250;

const Container = styled.div`
    background: white;
    left: 0;
    padding: 40px 80px;
    position: absolute;
    top: 0;
    width: 100%;
`;

const ButtonContainer = styled.div`
    position: absolute;
    right: 80px;
    top: 40px;
`;

const ReturnButtonContainer = styled(Button)`
    margin-right: 10px;
    margin-bottom: 10px;
`;

const ButtonHelperContainer = styled(Helper)`
    padding-left: 40px;
`;

const Title = styled.h1`
    color: ${({ theme }) => theme.color.brand100};
    font-size: ${({ theme }) => theme.fontSize.bigger};
    font-weight: normal;
    text-align: center;
    text-transform: uppercase;
`;

const AttributeLabel = styled.div`
    color: ${({ theme }) => theme.color.grey140};
    font-size: ${({ theme }) => theme.fontSize.title};
    padding-top: 1rem;
    text-align: center;
`;

const ColoredLabel = styled.span`
    color: ${({ theme }) => theme.color.brand100};
`;

const CloseIconThemed = styled(CloseIcon)`
    position: absolute;
    left: 43.5px;
    top: 40px;
    color: ${getColor('brand', 100)};
    cursor: pointer;
`;

const HelperSelection = styled.div`
    margin-top: 19px;
    margin-bottom: 45px;
`;

const InformationHelper = styled.div`
    margin-top: 20px;
`;

const Pipe = styled.span`
    margin: 0 20px 0 20px;
    width: 24px;
    height: 0px;
    border: 1px solid ${({ theme }) => theme.color.grey100};
    transform: rotate(90deg);
    flex: none;
    order: 1;
    flex-grow: 0;
`;
const NumberResultMetafields = styled.span`
    color: ${({ theme }) => theme.color.brand100};
`;

const FixedWidthTableHeaderCell = styled(Table.HeaderCell)`
    width: 10%;
`;

const FixedWidthTableHeaderSelectCell = styled(Table.HeaderCell)`
    width: 30%;
`;

const FixedWidthTableHeaderCellSelect = styled(Table.HeaderCell)`
    width: 10%;
`;

export const MetafieldsSelection = () => {
    const intl = useIntl();
    const navigate = useNavigate();

    const { saveMapping, isSaving } = useSaveMetafieldMapping();

    const { currentStoreId } = useCurrentStore();
    const [pimHasInstability, setPimHasInstability] = useState<boolean>(false);
    const [shopifyHasInstability, setShopifyHasInstability] =
        useState<boolean>(false);

    const { data: metafields } = useQuery(
        'retrievePimAttributesForMetafieldsSelection',
        () => retrievePimAttributesForMetafieldsSelection(currentStoreId),
        {
            onSuccess: (metafields) => {
                if (
                    metafields &&
                    metafields.error &&
                    metafields.error === InstabilityType.pim_instability
                ) {
                    setPimHasInstability(true);
                }
            },
        }
    );

    const { data: existingDefinition } = useQuery(
        'retrieveExistingShopifyMetafieldsDefinition',
        () => retrieveExistingShopifyMetafieldsDefinition(currentStoreId),
        {
            onSuccess: (existingShopifyMetafieldsDefinition) => {
                if (
                    existingShopifyMetafieldsDefinition &&
                    existingShopifyMetafieldsDefinition.error &&
                    existingShopifyMetafieldsDefinition.error ===
                        InstabilityType.shopify_instability
                ) {
                    setShopifyHasInstability(true);
                }
            },
        }
    );

    const [state, setMetafields] = useState<{ [code: string]: boolean }>({});

    const [existingProductDefinitionState, setExistingProductDefinition] =
        useState<{ [code: string]: string }>({});
    const [existingVariantDefinitionState, setExistingVariantDefinition] =
        useState<{ [code: string]: string }>({});

    const [originalMetafields, setOriginalMetafields] = useState<Metafield[]>(
        []
    );

    const [metafieldDeletionList, setMetafieldDeletionList] = useState<
        {
            label: string;
            reasonBy: {
                productDefinition: string;
                variantDefinition: string;
            };
        }[]
    >([]);

    const [isSelectionDirty, setSelectionDirty] = useState<boolean>(false);

    const selectMetafield = (attributeCode: string, value: boolean) => {
        setMetafields((state) => ({ ...state, [attributeCode]: value }));
        setSelectionDirty(true);
    };

    const selectProductMetafieldDefinition = (
        attributeCode: string,
        productValue: string,
        initialState: boolean
    ) => {
        setExistingProductDefinition((existingProductDefinitionState) => ({
            ...existingProductDefinitionState,
            [attributeCode]: productValue,
        }));

        if (!initialState) {
            setSelectionDirty(true);
        }
    };

    const selectVariantMetafieldDefinition = (
        attributeCode: string,
        variantValue: string,
        initialState: boolean
    ) => {
        setExistingVariantDefinition((existingVariantDefinitionState) => ({
            ...existingVariantDefinitionState,
            [attributeCode]: variantValue,
        }));

        if (!initialState) {
            setSelectionDirty(true);
        }
    };

    useQuery(
        [currentStoreId, 'getMetafieldMapping'],
        () => getMetafieldMapping(currentStoreId),
        {
            enabled: undefined !== existingDefinition && !shopifyHasInstability,
            onSuccess: (retrieveMetafields) => {
                const setMetafieldDefinition = (
                    metafield: Metafield,
                    metafieldDefinitionId: string | null,
                    metafieldDefinition: MetafieldDefinition,
                    selectMetafieldDefinition: (
                        attributeCode: string,
                        variantValue: string,
                        initialState: boolean
                    ) => void,
                    existingDefinitions?: MetafieldExistingDefinition[]
                ) => {
                    existingDefinitions?.forEach((existingDefinition) => {
                        // first case, the metafield is mapped with a pre-existing custom field from Shopify
                        if (
                            metafieldDefinitionId &&
                            existingDefinition.id === metafieldDefinitionId
                        ) {
                            metafieldDefinition.fromShopify = true;
                            metafieldDefinition.name = existingDefinition.name;

                            selectMetafieldDefinition(
                                metafield.code,
                                existingDefinition.name,
                                true
                            );

                            return;
                        }

                        // second case, metafield is created by App into Shopify on synchro
                        if (
                            existingDefinition.namespace === 'akeneo' &&
                            existingDefinition.key ===
                                metafield.code.toLowerCase()
                        ) {
                            metafieldDefinition.fromShopify = false;
                            metafieldDefinition.name = existingDefinition.name;

                            selectMetafieldDefinition(
                                metafield.code,
                                existingDefinition.name,
                                true
                            );
                            return;
                        }
                    });
                };

                retrieveMetafields.forEach((metafield) => {
                    metafield.productDefinition = {
                        name: null,
                        fromShopify: false,
                    };
                    setMetafieldDefinition(
                        metafield,
                        metafield.shopifyProductDefinitionId,
                        metafield.productDefinition,
                        selectProductMetafieldDefinition,
                        existingDefinition?.productDefinitions
                    );

                    metafield.variantDefinition = {
                        name: null,
                        fromShopify: false,
                    };
                    setMetafieldDefinition(
                        metafield,
                        metafield.shopifyVariantDefinitionId,
                        metafield.variantDefinition,
                        selectVariantMetafieldDefinition,
                        existingDefinition?.variantDefinitions
                    );
                });

                setOriginalMetafields(retrieveMetafields);
                let selectedMetafields: { [code: string]: boolean } = {};
                retrieveMetafields.map(
                    (metafield: Metafield) =>
                        (selectedMetafields[metafield.code] = true)
                );
                if (Object.keys(selectedMetafields).length > 0) {
                    setMetafields(selectedMetafields);
                }
            },
        }
    );

    const isCompatible = (
        shopifyMetafieldDefinitionType: string | null,
        pimAttributeType: string
    ) => {
        switch (pimAttributeType) {
            case PimAttributeType.pim_catalog_date:
                return (
                    shopifyMetafieldDefinitionType ===
                    MetafieldDefinitionType.date
                );
            case PimAttributeType.pim_catalog_identifier:
            case PimAttributeType.pim_catalog_simpleselect:
            case PimAttributeType.pim_catalog_metric:
            case PimAttributeType.pim_catalog_text:
                return (
                    shopifyMetafieldDefinitionType ===
                    MetafieldDefinitionType.single_line_text_field
                );
            case PimAttributeType.pim_catalog_multiselect:
                return (
                    shopifyMetafieldDefinitionType ===
                    MetafieldDefinitionType.list_single_line_text_field
                );
            case PimAttributeType.pim_catalog_number:
                return (
                    shopifyMetafieldDefinitionType ===
                    MetafieldDefinitionType.number_decimal
                );
            case PimAttributeType.pim_catalog_price_collection:
                return (
                    shopifyMetafieldDefinitionType ===
                    MetafieldDefinitionType.money
                );
            case PimAttributeType.pim_catalog_textarea:
                return (
                    shopifyMetafieldDefinitionType ===
                    MetafieldDefinitionType.multi_line_text_field
                );
            case PimAttributeType.pim_catalog_boolean:
                return (
                    shopifyMetafieldDefinitionType ===
                    MetafieldDefinitionType.boolean
                );
            default:
                return false;
        }
    };

    const getAvailableProductDefinition = (metafieldType: string) => {
        const existingProductDefinitionStateValues = Object.values(
            existingProductDefinitionState
        );
        return existingDefinition?.productDefinitions.filter(
            (productDefinition) => {
                if (!isCompatible(productDefinition.type, metafieldType)) {
                    return false;
                }

                return (
                    -1 ===
                    existingProductDefinitionStateValues.indexOf(
                        productDefinition.name
                    )
                );
            }
        );
    };

    const getAvailableVariantDefinition = (metafieldType: string) => {
        const existingVariantDefinitionStateValues = Object.values(
            existingVariantDefinitionState
        );
        return existingDefinition?.variantDefinitions.filter(
            (variantDefinition) => {
                if (!isCompatible(variantDefinition.type, metafieldType)) {
                    return false;
                }

                return (
                    -1 ===
                    existingVariantDefinitionStateValues.indexOf(
                        variantDefinition.name
                    )
                );
            }
        );
    };

    type metafieldSelectionByStore = {
        metafieldSelection: string[];
        storeId: string;
    };
    const getSelectedAttribute = () => {
        const metafieldSelectionByStoreId: metafieldSelectionByStore = {
            metafieldSelection: [],
            storeId: currentStoreId,
        };
        Object.keys(state).forEach((key) => {
            if (state[key] === true) {
                metafieldSelectionByStoreId.metafieldSelection.push(key);
            }
        });
        return metafieldSelectionByStoreId;
    };

    const getSelectedExistingProductDefinition = () => {
        const selectedExistingProductDefinition: existingDefinitionMapping = {};

        Object.entries(existingProductDefinitionState).forEach(
            ([code, value]) => {
                const definition = existingDefinition?.productDefinitions.find(
                    (productDefinition) => productDefinition.id === value
                );
                if (undefined !== definition) {
                    selectedExistingProductDefinition[code] = definition;
                }
            }
        );
        return selectedExistingProductDefinition;
    };

    const getSelectedExistingVariantDefinition = () => {
        const selectedExistingVariantDefinition: existingDefinitionMapping = {};

        Object.entries(existingVariantDefinitionState).forEach(
            ([code, value]) => {
                selectedExistingVariantDefinition[code] =
                    existingDefinition?.variantDefinitions.find(
                        (variantDefinition) => variantDefinition.id === value
                    );
            }
        );
        return selectedExistingVariantDefinition;
    };

    const maxAttributesReached =
        getSelectedAttribute().metafieldSelection.length >=
        MAX_SELECTED_ATTRIBUTES;

    const [isModalOpen, openModal, closeModal] = useBooleanState(false);

    const getMetafieldDeletionReason = (
        metafieldsSelection: metafieldSelectionByStore,
        metafield: Metafield,
        definition: MetafieldDefinition,
        existingDefinitions: { [code: string]: string }
    ): string => {
        // metafields already mapped before edition
        if (metafieldsSelection.metafieldSelection.includes(metafield.code)) {
            if (definition.fromShopify) {
                // case metafield was linked with existing definition AND is unlinked now
                if (!existingDefinitions[metafield.code]) {
                    return 'Existing metafield disassociation - new metafield creation';
                }
            } else {
                // case metafield was not mapped with existing definition AND is linked now
                if (existingDefinitions[metafield.code]) {
                    return 'Metafield deletion - existing metafield association';
                }
            }
        }
        // metafield unmapped
        else {
            if (definition.fromShopify) {
                return 'Out of synchronization';
            } else {
                return 'Metafield deletion';
            }
        }

        return '';
    };

    const alreadySavedMetafieldsUnselected = () => {
        let deleted = false;
        let deletionList: {
            label: string;
            reasonBy: {
                productDefinition: string;
                variantDefinition: string;
            };
        }[] = [];

        const selectedAttributes = getSelectedAttribute();
        originalMetafields.forEach((originalMetafield) => {
            const reasonDeletionByProductDef: string =
                getMetafieldDeletionReason(
                    selectedAttributes,
                    originalMetafield,
                    originalMetafield.productDefinition,
                    existingProductDefinitionState
                );

            const reasonDeletionByVariantDef: string =
                getMetafieldDeletionReason(
                    selectedAttributes,
                    originalMetafield,
                    originalMetafield.variantDefinition,
                    existingVariantDefinitionState
                );

            if (reasonDeletionByProductDef || reasonDeletionByVariantDef) {
                deleted = true;
                deletionList.push({
                    label: originalMetafield.label,
                    reasonBy: {
                        productDefinition: reasonDeletionByProductDef,
                        variantDefinition: reasonDeletionByVariantDef,
                    },
                });
            }
        });
        setMetafieldDeletionList(deletionList);

        return deleted;
    };

    const handleSave = async () => {
        if (alreadySavedMetafieldsUnselected()) {
            openModal();
        } else {
            await saveSelection();
        }
    };

    const saveSelection = async () => {
        await saveMapping(
            getSelectedAttribute(),
            getSelectedExistingProductDefinition(),
            getSelectedExistingVariantDefinition()
        );
        navigate('..');
    };

    const [selectedFilter, setSelectedFilter] = useState<
        'all' | 'selected' | 'unselected'
    >('all');

    let [searchValue, setSearchValue] = useState('');

    const filteredMetafields = useMemo(() => {
        if (
            metafields &&
            metafields.error === InstabilityType.pim_instability
        ) {
            return;
        }
        return metafields?.pimAttributes?.filter((metafield) => {
            const { code, label } = metafield;
            const inFilter =
                (selectedFilter === 'selected' && state[code]) ||
                (selectedFilter === 'unselected' && !state[code]) ||
                selectedFilter === 'all';

            if (searchValue === '') {
                return inFilter;
            }

            const inSearch =
                code
                    .toString()
                    .toUpperCase()
                    .includes(searchValue.toUpperCase()) ||
                label
                    .toString()
                    .toUpperCase()
                    .includes(searchValue.toUpperCase());

            return inFilter && inSearch;
        });
    }, [metafields, selectedFilter, state, searchValue]);

    return (
        <Container>
            <ButtonContainer>
                <ReturnButtonContainer
                    level='tertiary'
                    onClick={() => navigate('..')}
                    disabled={isSaving}
                >
                    <FormattedMessage id='0WJNP/' defaultMessage='Return' />
                </ReturnButtonContainer>
                <Button
                    level='primary'
                    disabled={!isSelectionDirty || isSaving}
                    onClick={handleSave}
                >
                    <FormattedMessage
                        id='b5nahW'
                        defaultMessage='Save selection'
                    />
                </Button>
                {isSelectionDirty && (
                    <ButtonHelperContainer inline level='warning'>
                        <FormattedMessage
                            id='0hzJRW'
                            defaultMessage='There are unsaved changes.'
                        />
                    </ButtonHelperContainer>
                )}
            </ButtonContainer>

            <Title>
                <FormattedMessage id='i1xTYg' defaultMessage='Metafields' />
                <br />
            </Title>
            <AttributeLabel>
                <FormattedMessage id='xLigG0' defaultMessage='Selection' />
            </AttributeLabel>
            <CloseIconThemed size={16} onClick={() => navigate('..')} />

            <HelperSelection>
                <Helper level='info'>
                    <FormattedMessage
                        id='Y29ztN'
                        defaultMessage='Please note that you can only select {maxMetafields} metafields when mapping product information.'
                        values={{ maxMetafields: MAX_SELECTED_ATTRIBUTES }}
                    />
                </Helper>
            </HelperSelection>
            <Search
                onSearchChange={setSearchValue}
                placeholder='Search'
                searchValue={searchValue}
                title='Search'
            >
                <div>
                    <NumberResultMetafields>
                        {filteredMetafields?.length}
                        &nbsp;
                    </NumberResultMetafields>
                    <FormattedMessage id='8quEg9' defaultMessage='results' />
                    <Pipe></Pipe>
                </div>
                <SelectedFilter
                    value={selectedFilter}
                    onChange={setSelectedFilter}
                />
            </Search>
            {!pimHasInstability &&
            !shopifyHasInstability &&
            filteredMetafields !== undefined ? (
                filteredMetafields.length > 0 ? (
                    <>
                        <Table>
                            <Table.Header>
                                <FixedWidthTableHeaderCellSelect></FixedWidthTableHeaderCellSelect>
                                <FixedWidthTableHeaderCell>
                                    <FormattedMessage
                                        id='753yX5'
                                        defaultMessage='Label'
                                    />
                                </FixedWidthTableHeaderCell>

                                <FixedWidthTableHeaderCell>
                                    <FormattedMessage
                                        id='h2vipu'
                                        defaultMessage='Code'
                                    />
                                </FixedWidthTableHeaderCell>
                                <FixedWidthTableHeaderCell>
                                    <FormattedMessage
                                        id='+U6ozc'
                                        defaultMessage='Type'
                                    />
                                </FixedWidthTableHeaderCell>
                                <FixedWidthTableHeaderSelectCell>
                                    <FormattedMessage
                                        id='IbhM4f'
                                        defaultMessage='Existing Shopify product metafield (optional)'
                                    />
                                </FixedWidthTableHeaderSelectCell>
                                <FixedWidthTableHeaderSelectCell>
                                    <FormattedMessage
                                        id='Z4jcqY'
                                        defaultMessage='Existing Shopify variant metafield (optional)'
                                    />
                                </FixedWidthTableHeaderSelectCell>
                            </Table.Header>
                            <Table.Body>
                                {filteredMetafields.map((metafield) => (
                                    <Table.Row key={metafield.code}>
                                        <Table.Cell>
                                            <Checkbox
                                                checked={
                                                    state[metafield.code] ||
                                                    false
                                                }
                                                readOnly={
                                                    isSaving ||
                                                    (maxAttributesReached &&
                                                        !state[metafield.code])
                                                }
                                                onChange={(value) =>
                                                    selectMetafield(
                                                        metafield.code,
                                                        value
                                                    )
                                                }
                                            ></Checkbox>
                                        </Table.Cell>
                                        <Table.Cell>
                                            <ColoredLabel>
                                                {metafield.label}
                                            </ColoredLabel>
                                        </Table.Cell>
                                        <Table.Cell>
                                            {metafield.code}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {metafield.attributeTypeLabel}
                                        </Table.Cell>
                                        {state[metafield.code] && (
                                            <>
                                                <Table.Cell>
                                                    <SelectInput
                                                        placeholder={intl.formatMessage(
                                                            {
                                                                id: 'JWNjAt',
                                                                defaultMessage:
                                                                    'Select existing shopify product metafield',
                                                            }
                                                        )}
                                                        openLabel=''
                                                        value={
                                                            existingProductDefinitionState[
                                                                metafield.code
                                                            ] || null
                                                        }
                                                        onChange={(
                                                            value: string
                                                        ) =>
                                                            selectProductMetafieldDefinition(
                                                                metafield.code,
                                                                value,
                                                                false
                                                            )
                                                        }
                                                        emptyResultLabel={intl.formatMessage(
                                                            {
                                                                id: 'Vv5jEs',
                                                                defaultMessage:
                                                                    'No product metafield definition found',
                                                            }
                                                        )}
                                                    >
                                                        {Object.entries(
                                                            getAvailableProductDefinition(
                                                                metafield.type
                                                            ) || {}
                                                        ).map(
                                                            ([
                                                                index,
                                                                metafield,
                                                            ]) => (
                                                                <SelectInput.Option
                                                                    key={
                                                                        metafield.key
                                                                    }
                                                                    value={
                                                                        metafield.id
                                                                    }
                                                                >
                                                                    {
                                                                        metafield.name
                                                                    }
                                                                </SelectInput.Option>
                                                            )
                                                        )}
                                                    </SelectInput>
                                                </Table.Cell>
                                                <Table.Cell>
                                                    <SelectInput
                                                        placeholder={intl.formatMessage(
                                                            {
                                                                id: 'jue/VO',
                                                                defaultMessage:
                                                                    'Select existing shopify variant metafield',
                                                            }
                                                        )}
                                                        openLabel=''
                                                        value={
                                                            existingVariantDefinitionState[
                                                                metafield.code
                                                            ] || null
                                                        }
                                                        onChange={(
                                                            value: string
                                                        ) =>
                                                            selectVariantMetafieldDefinition(
                                                                metafield.code,
                                                                value,
                                                                false
                                                            )
                                                        }
                                                        emptyResultLabel={intl.formatMessage(
                                                            {
                                                                id: 'anqCep',
                                                                defaultMessage:
                                                                    'No variant metafield definition found',
                                                            }
                                                        )}
                                                    >
                                                        {Object.entries(
                                                            getAvailableVariantDefinition(
                                                                metafield.type
                                                            ) || {}
                                                        ).map(
                                                            ([
                                                                index,
                                                                metafield,
                                                            ]) => (
                                                                <SelectInput.Option
                                                                    key={
                                                                        metafield.key
                                                                    }
                                                                    value={
                                                                        metafield.id
                                                                    }
                                                                >
                                                                    {
                                                                        metafield.name
                                                                    }
                                                                </SelectInput.Option>
                                                            )
                                                        )}
                                                    </SelectInput>
                                                </Table.Cell>
                                            </>
                                        )}
                                    </Table.Row>
                                ))}
                            </Table.Body>
                        </Table>
                        {filteredMetafields.length >= 1000 ? (
                            <InformationHelper>
                                <Information
                                    illustration={<AssetsIllustration />}
                                    title={
                                        <FormattedMessage
                                            id='dT1Wyw'
                                            defaultMessage='Why some PIM attributes are missing in the list ?'
                                        />
                                    }
                                >
                                    <FormattedMessage
                                        id='kBfjzb'
                                        defaultMessage='Your PIM contains more than 1000 eligible attributes, and only 1000 could have been imported into the app. If you are missing an essential attribute that you would like to import, please get in touch with our Support team for help.'
                                    />
                                </Information>
                            </InformationHelper>
                        ) : (
                            <></>
                        )}
                    </>
                ) : searchValue === '' ? (
                    <NoPimAttribute />
                ) : (
                    <NoPimAttributeInSearch />
                )
            ) : pimHasInstability ? (
                <InstabilityStickyHelper source='PIM' />
            ) : shopifyHasInstability ? (
                <InstabilityStickyHelper source='SHOPIFY' />
            ) : (
                <Loading />
            )}
            {isModalOpen && (
                <MetafieldDeselectionModal
                    closeModal={closeModal}
                    metafieldDeletionList={metafieldDeletionList}
                    save={saveSelection}
                />
            )}
        </Container>
    );
};
