import React from 'react';
import styled from 'styled-components';
import { ConfigurationReport } from './ConfigurationReport';
import { GlobalReport } from './GlobalReport';
import {
    ConfigurationModel,
    GlobalReportModel,
    SynchronizationModel,
} from '../model/SynchronizationReportModel';

const ConfigurationGlogalReportContainer = styled.div`
    display: flex;
    align-content: space-between;
    gap: 10px;
`;

type Props = {
    configuration: ConfigurationModel | null;
    globalReport: GlobalReportModel | null;
    synchronization: SynchronizationModel | null;
    synchronizationId: string;
};

export const ConfigurationGlogalReport = ({
    configuration,
    globalReport,
    synchronization,
    synchronizationId,
}: Props) => {
    return (
        <>
            <ConfigurationGlogalReportContainer>
                <ConfigurationReport
                    configuration={configuration}
                    synchronization={synchronization}
                />
                <GlobalReport
                    globalReport={globalReport}
                    synchronizationId={synchronizationId}
                />
            </ConfigurationGlogalReportContainer>
        </>
    );
};
