import { apiFetch } from '../../../util/apiFetch';

export type metafieldSelectionByStore = {
    metafieldSelection: string[];
    storeId: string;
};

export type existingDefinitionMapping = {
    [p: string]:
        | {
              id: string;
              key: string;
              name: string;
              namespace: string;
              ownerType: string;
              type: string | null;
          }
        | undefined;
};

export const saveMetafieldMapping = (
    metafieldSelection: metafieldSelectionByStore,
    existingProductDefinitionMapping: existingDefinitionMapping,
    existingVariantDefinitionMapping: existingDefinitionMapping
) => {
    return apiFetch(
        `${metafieldSelection.storeId}/metafield/save-metafield-mapping`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                metafieldSelection: metafieldSelection.metafieldSelection,
                existingProductDefinitionMapping:
                    existingProductDefinitionMapping,
                existingVariantDefinitionMapping:
                    existingVariantDefinitionMapping,
            }),
        }
    );
};
