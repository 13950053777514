import { FormattedMessage, useIntl } from 'react-intl';
import {
    AssociationTypesIllustration,
    Link,
    Placeholder,
} from 'akeneo-design-system';
import { helpCenterAssociationsMappingUrl } from '../../../util/config';
import { ManageMetafieldsButton } from '../../Metafields/ManageMetafieldsButton';
import React from 'react';
import styled from 'styled-components';

const MessageContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-top: 15px;
    font-size: 15px;
    line-height: 18px;
    align-items: center;
`;

const linkStyle = {
    marginBottom: '10px',
};

export const NoMetafieldsFromPimAssociations = () => {
    const intl = useIntl();

    return (
        <>
            <Placeholder
                illustration={<AssociationTypesIllustration />}
                size='large'
                title={intl.formatMessage({
                    id: 'qvaC2P',
                    defaultMessage:
                        'No metafields from PIM associations for the moment',
                })}
            />
            <MessageContainer>
                <FormattedMessage
                    id='avTkW+'
                    defaultMessage='To map metafields, use "Select metafields" button.'
                />
                <Link
                    href={helpCenterAssociationsMappingUrl}
                    target='_blank'
                    style={linkStyle}
                >
                    <FormattedMessage
                        id='43uZEt'
                        defaultMessage='Visit our Help Center for more information'
                    />
                </Link>

                <ManageMetafieldsButton noMappedMetafields={true} />
            </MessageContainer>
        </>
    );
};
