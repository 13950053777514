import { apiFetch } from '../../../util/apiFetch';

type Result = {
    pimAttributes: {
        code: string;
        type: string;
        label: string;
        attributeTypeLabel: string;
    }[];
    error?: string;
} | null;

export const retrievePimAttributesForMetafieldsSelection = (storeId: string) =>
    apiFetch<Result>(
        `${storeId}/metafield/retrieve-pim-attributes-for-metafield-selection`
    ).then((result) => result);
