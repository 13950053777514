import { Route, Routes } from 'react-router-dom';
import { MetafieldsSelected } from './component/MetafieldsSelected';
import { MetafieldsSelection } from './component/MetafieldsSelection';
import { DisabledMapping } from '../NativeFields/component/DisabledMapping';
import { InvalidConfiguration } from '../NativeFields/component/InvalidConfiguration';
import React from 'react';
import { RunningSynchronizationGuard } from '../Synchronization';
import { ValidConfigurationGuard } from '../Configuration';

export const Metafields = () => {
    return (
        <RunningSynchronizationGuard fallback={() => <DisabledMapping />}>
            <ValidConfigurationGuard
                fallback={(isConfigInvalid) => (
                    <InvalidConfiguration isConfigInvalid={isConfigInvalid} />
                )}
            >
                <Routes>
                    <Route path='selection' element={<MetafieldsSelection />} />
                    <Route path='*' element={<MetafieldsSelected />} />
                </Routes>
            </ValidConfigurationGuard>
        </RunningSynchronizationGuard>
    );
};
