import { apiFetch } from '../../../util/apiFetch';

export type MetafieldExistingDefinition = {
    id: string;
    key: string;
    name: string;
    namespace: string;
    ownerType: string;
    type: string | null;
};

type Result = {
    productDefinitions: MetafieldExistingDefinition[];
    variantDefinitions: MetafieldExistingDefinition[];
    error?: string;
} | null;

export const retrieveExistingShopifyMetafieldsDefinition = (storeId: string) =>
    apiFetch<Result>(
        `${storeId}/metafield/retrieve-existing-shopify-metafield-definition`
    ).then((result) => result);
