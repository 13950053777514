import { useIntl } from 'react-intl';
import { useToaster } from '../../util/toast';
import {
    existingDefinitionMapping,
    metafieldSelectionByStore,
    saveMetafieldMapping,
} from './api/saveMetafieldMapping';
import { useState } from 'react';

export const useSaveMetafieldMapping = () => {
    const intl = useIntl();
    const addToast = useToaster();
    const [isSaving, setIsSaving] = useState(false);

    const saveMapping = async (
        metafieldSelection: metafieldSelectionByStore,
        existingProductDefinitionMapping: existingDefinitionMapping,
        existingVariantDefinitionMapping: existingDefinitionMapping
    ) => {
        setIsSaving(true);

        await saveMetafieldMapping(
            metafieldSelection,
            existingProductDefinitionMapping,
            existingVariantDefinitionMapping
        );

        addToast(
            intl.formatMessage({
                id: 'Lkxe3S',
                defaultMessage: 'Metafield selection has been saved.',
            }),
            'success'
        );

        setIsSaving(false);
    };

    return { saveMapping, isSaving };
};
