import { apiFetch } from '../../../util/apiFetch';

export type MetafieldDefinition = {
    name: string | null;
    fromShopify: boolean;
};

export type Metafield = {
    code: string;
    type: string;
    label: string;
    attributeTypeLabel: string;

    shopifyProductDefinitionId: string | null;
    shopifyVariantDefinitionId: string | null;

    productDefinition: MetafieldDefinition;

    variantDefinition: MetafieldDefinition;
};

type Result = {
    metafieldMapping: Metafield[];
};

export const getMetafieldMapping = (storeId: string) =>
    apiFetch<Result>(`${storeId}/metafield/retrieve-metafield-mapping`).then(
        (result) => result?.metafieldMapping ?? []
    );
