import { apiFetch } from '../../../util/apiFetch';

export type associationSelectionByStore = {
    associationSelection: string[];
    storeId: string;
};
export const saveAssociationMapping = (
    associationSelection: associationSelectionByStore
) =>
    apiFetch(
        `${associationSelection.storeId}/metafield/save-association-mapping`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                associationSelection: associationSelection.associationSelection,
            }),
        }
    );
