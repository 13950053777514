import { apiFetch } from '../../../util/apiFetch';
import { AssociationMapping } from '../model/AssociationsModel';

type Result = {
    mapping: AssociationMapping;
};

export const getAssociationMappingQueryKey = 'getAssociationMapping';

export const getAssociationMapping = (storeId: string) =>
    apiFetch<Result>(`${storeId}/metafield/get-association-mapping`).then(
        (result) => result
    );
