import { Helper, SectionTitle } from 'akeneo-design-system';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useMutation, useQuery } from 'react-query';
import { Loading } from '../../../component';
import { fetchSynchronizations } from '../api/fetchSynchronizations';
import { fetchFilterPimAttributes } from '../api/fetchFilterPimAttributes';
import { launchSynchronization } from '../api/launchSynchronization';
import { SynchronizationScope } from '../model/SynchronizationScope';
import { useDispatch, useSelector } from '../store/StoreProvider';
import { SynchronizationItem } from './component/SynchronizationItem';
import { SynchronizationList } from './component/SynchronizationList';
import { useNotifyFinishedSynchronization } from './hooks/useNotifyFinishedSynchronization';
import { stopSynchronization } from '../api/stopSynchronization';
import { saveScheduledSyncConfig } from '../api/saveScheduledSyncConfig';
import { useCurrentStore } from '../../MultiStore';
import { FilterPimAttribute } from '../model/FilterPimAttribute';
import { isProductPreExistingCatalogMappingConfigured } from '../api/IsProductPreExistingCatalogMappingConfigured';
import { isVariantPreExistingCatalogMappingConfigured } from '../api/IsVariantPreExistingCatalogMappingConfigured';
import { isProductMediaMappingEmpty } from '../api/IsProductMediaMappingEmpty';
import { isProductWithVariantsMediaMappingEmpty } from '../api/IsProductWithVariantsMediaMappingEmpty';
import { isMetafieldMappingEmpty as isMetafieldMappingEmptyApi } from '../api/IsMetafieldMappingEmpty';
import { isSecondaryLocalesMappingEmpty as isSecondaryLocalesMappingEmptyApi } from '../api/IsSecondaryLocalesMappingEmpty';
import { isMarketsPricingMappingEmpty as isMarketsPricingMappingEmptyApi } from '../api/IsMarketsPricingMappingEmpty';
import { dateTimeToIso8601 } from '../../../util/dateTimeToIso8601';
import { DateTime } from 'luxon';
import { isAssociationMappingEmptyApi } from '../api/IsAssociationMappingEmpty';

export const Synchronization = () => {
    const dispatch = useDispatch();
    const { filters, synchronizations } = useSelector((state) => state);
    const [filterPimAttributes, setFilterPimAttributes] = useState<
        FilterPimAttribute[]
    >([]);
    const [
        productPreExistingCatalogMappingConfigured,
        setProductPreExistingCatalogMappingConfigured,
    ] = useState(false);

    const [
        variantPreExistingCatalogMappingConfigured,
        setVariantPreExistingCatalogMappingConfigured,
    ] = useState(false);

    const [
        isSimpleProductMediaMappingEmpty,
        setIsSimpleProductMediaMappingEmpty,
    ] = useState(false);

    const [
        isProductVariantsMediaMappingEmpty,
        seIsProductVariantsMediaMappingEmpty,
    ] = useState(false);

    const [isMetafieldMappingEmpty, setIsMetafieldMappingEmpty] =
        useState(false);

    const [isAssociationMappingEmpty, setIsAssociationMapping] =
        useState(false);

    const [isSecondaryLocalesMappingEmpty, setIsSecondaryLocalesMappingEmpty] =
        useState(false);

    const [isMarketsPricingMappingEmpty, setIsMarketsPricingMappingEmpty] =
        useState(false);

    const stopSynchronizationMutation = useMutation(stopSynchronization);

    const launchSynchronizationMutation = useMutation(launchSynchronization);

    useNotifyFinishedSynchronization();

    const { currentStoreId } = useCurrentStore();

    const { isLoading, refetch } = useQuery(
        'fetchSynchronizations',
        () => fetchSynchronizations(currentStoreId),
        {
            enabled: !launchSynchronizationMutation.isLoading,
            refetchInterval: 5000,
            onSuccess: (synchronizations) => {
                dispatch({
                    type: 'synchronization/fetchSynchronizations/fulfilled',
                    payload: synchronizations,
                });
            },
        }
    );

    useQuery(
        'fetchFilterPimAttributes',
        () => fetchFilterPimAttributes(currentStoreId),
        {
            onSuccess: (data) => {
                setFilterPimAttributes(data);
            },
        }
    );

    useQuery(
        'isProductPreExistingCatalogMappingConfigured',
        () => isProductPreExistingCatalogMappingConfigured(currentStoreId),
        {
            onSuccess: (result) => {
                setProductPreExistingCatalogMappingConfigured(
                    result.isConfigured
                );
            },
        }
    );

    useQuery(
        'isVariantPreExistingCatalogMappingConfigured',
        () => isVariantPreExistingCatalogMappingConfigured(currentStoreId),
        {
            onSuccess: (result) => {
                setVariantPreExistingCatalogMappingConfigured(
                    result.isConfigured
                );
            },
        }
    );

    useQuery(
        'isProductMediaMappingEmpty',
        () => isProductMediaMappingEmpty(currentStoreId),
        {
            onSuccess: (result) => {
                setIsSimpleProductMediaMappingEmpty(result.isEmpty);
            },
        }
    );

    useQuery(
        'isProductWithVariantsMediaMappingEmpty',
        () => isProductWithVariantsMediaMappingEmpty(currentStoreId),
        {
            onSuccess: (result) => {
                seIsProductVariantsMediaMappingEmpty(result.isEmpty);
            },
        }
    );

    useQuery(
        'isMetafieldMappingEmpty',
        () => isMetafieldMappingEmptyApi(currentStoreId),
        {
            onSuccess: (result) => {
                setIsMetafieldMappingEmpty(result.isEmpty);
            },
        }
    );

    useQuery(
        'isAssociationMappingEmpty',
        () => isAssociationMappingEmptyApi(currentStoreId),
        {
            onSuccess: (result) => {
                setIsAssociationMapping(result.isEmpty);
            },
        }
    );

    useQuery(
        'isSecondaryLocalesMappingEmpty',
        () => isSecondaryLocalesMappingEmptyApi(currentStoreId),
        {
            onSuccess: (result) => {
                setIsSecondaryLocalesMappingEmpty(result.isEmpty);
            },
        }
    );

    useQuery(
        'isMarketsPricingMappingEmpty',
        () => isMarketsPricingMappingEmptyApi(currentStoreId),
        {
            onSuccess: (result) => {
                setIsMarketsPricingMappingEmpty(result.isEmpty);
            },
        }
    );

    const isPreExistingCatalogMappedByScope = (scope: string): boolean => {
        return (
            (scope === SynchronizationScope.Product &&
                productPreExistingCatalogMappingConfigured) ||
            (scope === SynchronizationScope.ProductVariant &&
                variantPreExistingCatalogMappingConfigured)
        );
    };

    const isProductMediaMappingEmptyByScope = (scope: string): boolean => {
        return (
            (scope === SynchronizationScope.Product &&
                isSimpleProductMediaMappingEmpty) ||
            (scope === SynchronizationScope.ProductVariant &&
                isProductVariantsMediaMappingEmpty)
        );
    };

    const saveScheduledSyncConfigMutation = useMutation(
        saveScheduledSyncConfig,
        {
            onSuccess: () => {
                refetch();
            },
        }
    );

    const handleLaunch = (
        scope: SynchronizationScope,
        minCompleteness: number | null,
        filterAttribute: string | null
    ) => {
        dispatch({
            type: 'synchronization/launchSynchronization/pending',
            payload: {
                scope: scope,
                createdAt: dateTimeToIso8601(DateTime.utc()),
                referenceDate:
                    filters.method === 'DIFFERENTIAL' ? filters.updated : null,
            },
        });
        launchSynchronizationMutation.mutate({
            scope,
            updated: filters.method === 'DIFFERENTIAL' ? filters.updated : null,
            minCompleteness,
            filterAttribute,
            medias: !isProductMediaMappingEmptyByScope(scope)
                ? filters.medias
                : false,
            metafields: !isMetafieldMappingEmpty ? filters.metafields : false,
            associations: !isAssociationMappingEmpty
                ? filters.associations
                : false,
            secondaryLocales: !isSecondaryLocalesMappingEmpty
                ? filters.secondaryLocales
                : false,
            marketsPricing: !isMarketsPricingMappingEmpty
                ? filters.marketsPricing
                : false,
            preExistingProductsOnly: isPreExistingCatalogMappedByScope(scope)
                ? filters.preExistingProductsOnly
                : false,
            currentStoreId,
        });
    };

    const handleStop = (id: string, scope: SynchronizationScope) => {
        dispatch({
            type: 'synchronization/stopSynchronization/pending',
            payload: { scope: scope },
        });
        stopSynchronizationMutation.mutate({
            id,
            currentStoreId,
        });
    };

    const handleScheduleSync = (
        scope: SynchronizationScope,
        frequency: string,
        filterAttribute: string | null,
        importSecondaryLocales: boolean,
        importMarketsPricing: boolean,
        importPreExistingProductsOnly: boolean,
        importAssociations: boolean
    ) => {
        saveScheduledSyncConfigMutation.mutate({
            currentStoreId,
            frequency,
            scope,
            filterAttribute,
            importSecondaryLocales,
            importMarketsPricing,
            importPreExistingProductsOnly,
            importAssociations,
        });
    };

    if (isLoading) {
        return <Loading />;
    }

    return (
        <>
            <SectionTitle>
                <SectionTitle.Title>
                    <FormattedMessage
                        id='eOsloh'
                        defaultMessage='Synchronization'
                    />
                </SectionTitle.Title>
            </SectionTitle>

            <Helper level='info'>
                <FormattedMessage
                    defaultMessage='Here you can only find in progress synchronizations.'
                    id='47opYf'
                />
            </Helper>

            <SynchronizationList>
                {Object.values(synchronizations)
                    .sort((synchronizationA, synchronizationB) =>
                        synchronizationA.scope <= synchronizationB.scope
                            ? -1
                            : 1
                    )
                    .map((synchronization) => (
                        <SynchronizationItem
                            key={synchronization.scope}
                            synchronization={synchronization}
                            onLaunch={(
                                minCompleteness: number | null,
                                filterAttribute: string | null
                            ) =>
                                handleLaunch(
                                    synchronization.scope,
                                    minCompleteness,
                                    filterAttribute
                                )
                            }
                            onSchedule={(
                                frequency: string,
                                filterAttribute: string | null,
                                importSecondaryLocales: boolean | null,
                                importMarketsPricing: boolean | null,
                                importPreExistingProductsOnly: boolean | null,
                                importAssociations: boolean | null
                            ) =>
                                handleScheduleSync(
                                    synchronization.scope,
                                    frequency,
                                    filterAttribute,
                                    null === importSecondaryLocales ||
                                        importSecondaryLocales,
                                    null === importMarketsPricing ||
                                        importMarketsPricing,
                                    isPreExistingCatalogMappedByScope(
                                        synchronization.scope
                                    )
                                        ? null ===
                                              importPreExistingProductsOnly ||
                                              importPreExistingProductsOnly
                                        : false,
                                    null === importAssociations ||
                                        importAssociations
                                )
                            }
                            onStop={handleStop}
                            filterPimAttributes={filterPimAttributes}
                            isPreExistingCatalogMappingConfigured={isPreExistingCatalogMappedByScope(
                                synchronization.scope
                            )}
                            isProductMediaMappingEmpty={isProductMediaMappingEmptyByScope(
                                synchronization.scope
                            )}
                            isMetafieldMappingEmpty={isMetafieldMappingEmpty}
                            isAssociationMappingEmpty={
                                isAssociationMappingEmpty
                            }
                            isSecondaryLocalesMappingEmpty={
                                isSecondaryLocalesMappingEmpty
                            }
                            isMarketsPricingMappingEmpty={
                                isMarketsPricingMappingEmpty
                            }
                        />
                    ))}
            </SynchronizationList>
        </>
    );
};
