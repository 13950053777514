import { apiFetch } from '../../../util/apiFetch';
import { Associations } from '../model/AssociationsModel';

type Result = {
    pimAssociations?: Associations;
    error?: string;
};

export const getPimAssociationsQueryKey = 'getPimAssociations';

export const getPimAssociations = (storeId: string) =>
    apiFetch<Result>(`${storeId}/metafield/get-associations`).then(
        (result) => result
    );
